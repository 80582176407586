  @import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0px !important;
  padding: 0px !important;
  box-sizing: border-box;
  overflow-x: hidden;
}

* {
  font-family: 'Poppins', sans-serif;
}
a {
  text-decoration: none !important;
}
.cursor:hover{
  cursor: pointer;
}
.error {
  font-family: 'Montserrat', sans-serif;
  color: black;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.1rem;
  margin-top: 0.5rem;
  padding: 5px 10px;
  background-color: #F7EEDD;
  /* background-color: rgb(255, 0, 0, 0.2); */
}
.toast {
  background-color: #F7EEDD !important;
}
main {
  padding: 0px !important;
  flex: 1 0 auto;
  background-color: rgb(240, 240, 235, 0.3);
  overscroll-behavior: none !important;
  margin-top: 190px;
}

.main_body {
  overflow: hidden;
  max-width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
}

.whatsapp-btn_main {
  background-color: #25d366 ;
  position: absolute;
  position: fixed;
  bottom: 5%;
  right: 2%;
  color: white;
  cursor: pointer;
  border-radius: 60px;
  font-weight: 500;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width:992px){
  main {
    margin-top: 126px !important;
  }
}