.login-btns {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 30px 0px;
}

.login-btns button {
    width: fit-content;
    height: fit-content;
    padding: 10px 35px;
    background-color: #F7F7F7;
    color: #A5A5A5;

}
.password-toggle-btn{
    position: absolute;
    top: 16px;
    right: 10px;
    background-color: transparent;
    border: none;
    outline: none;
}
.login_btn {
    width: 100%;
    color: white;
    border: none;
    outline: none;
    padding: 10px; 
    background-color: #1b2950 !important;
}

.login_form_input {
    outline: none;
    padding: 15px 5px 15px 10px;
}
.form_icon{
    position: absolute;
    top: 3px;
    left: 8px;
    color:#1b2950;
}
.login_btn:hover {
    color: white;

}

.login_form_input:focus {
    outline: none;
    border: none;
}

.register_btn {
    cursor: pointer;
    color: darkblue;
    font-weight: 600;
    border-bottom: 1px solid darkblue;
}

.active_btn {
    border: none !important;
    outline: none !important;
    color: white !important;
    font-weight: 600;
    background-color: #1b2950 !important;
    transition: all ease-in-out 0.5s;
}
.form_label{
    display: flex;
    align-items: center;
    color: #1b2950;
    gap: 8px;
    margin: 0px 5px 10px 0px;
}
form span{
    font-size: 15px;
}

@media screen and (max-width:768px) {
    .hide_div {
        display: none !important;
    }
}