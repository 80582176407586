.nav_cotact {
  display: flex;
}

.nav_cotact p {
  color: white;
}

.nav_cotact a,
.nav_contact2 a {
  color: white;
}

.nav_cotact a:hover {
  cursor: pointer;
}

.nav_cotact_icon,
.nav_cotact_icon p {
  font-size: 20px;
  font-weight: 600;
}

.nav_contact3 {
  display: none;
}

.nav_contact2 span {
  font-size: 18px;
}

.logo_navbar {
  width: 200px;
}

.navbar {
  padding: 0px 0px 0px 0px;
}

.nav1 {
  vertical-align: middle;
  align-items: center;
}

.nav-item .nav-link {
  font-size: 18px;
}

.nav-item .nav-link1 {
  color: white !important;
}

.nav-item2 {
  border: none !important;
}

.nav-item,
.nav-item2 {
  list-style: none;
}

.nav-item2 {
  padding: 5px 10px;
  font-weight: 500;
}

.nav-item2:hover {
  cursor: pointer !important;
  font-weight: 600;
  background-color: #1b2950;
}

.nav-item2:hover .nav-link {
  color: white !important;
}

.nav-link {
  padding: 10px 5px;
}

.nav-item a,
.nav-item2 a {
  color: black !important;
}

.nav-item2 .nav-link {
  font-size: 17px;
}


.cart-white {
  color: #fff !important;
}

.cart-red {
  color: red;
}

.dropdown-item {
  padding: 10px 15px;
}

.dropdown-item:hover {
  background-color: #1b2950;
  padding-left: 20px;
  color: #ffff !important;
  transition: all linear 0.3s;
}

.navbar-toggler:focus {
  border: none !important;
  outline: none !important;
}

.dropdown-toggle1::after {
  color: white;
}

.dropdown-item.active {
  color: white !important;
  background-color: #1b2950 !important;
}

.custom-toggler {
  opacity: 0;
  color: white !important;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 7px;
}

.additional-class {
  top: -52px;
}

.login_div {
  position: absolute;
  top: 80px;
  right: 10px;
  width: 320px;
  height: fit-content;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  z-index: 104 !important;
}

.login_div::before {
  content: "";
  position: absolute;
  top: -10px;
  right: 68px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid white;
}

.login_div::-webkit-scrollbar-thumb {
  background: #1b2950;
  border-radius: 50rem;
  max-height: 40px !important;
}

.login_div::-webkit-scrollbar {
  width: 7px;
  max-height: 10px !important;
}

.login_div p {
  color: #1b2950 !important;
}

.dropdown-menu {
  margin-bottom: 0px;
  background-color: #F7EEDD;
  border: none;
}

.dropdown-menu li {
  border-bottom: 1px solid lightgray;
}

.menu3 {
  background-color: #F7EEDD !important;
}

.cart_number {
  position: absolute;
  top: 11px;
  right: -2px;
  width: 17px;
  height: 17px;
  background-color: red;
  color: white;
  font-size: 12px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ///////////////////////Side cart start ///////////////////////////// */


.side_cart {
  position: absolute;
  width: 320px !important;
  /* display: none; */
}

.nav_cart_div {
  flex: 1 0 auto;
  height: 67vh;
  overflow: scroll;
}

.nav_cart_div::-webkit-scrollbar {
  width: 0;
}

.nav_cart_div {
  scrollbar-width: none;
}

.side_open {
  position: absolute !important;
  position: fixed !important;
  display: block;
  width: 320px;
  height: 100%;
  top: 0px;
  right: 0px;
  background-color: white;
  padding: 10px;
  /* box-shadow: 1px 1px 5px rgb(0, 0, 0, 0.5); */
  z-index: 5000;
  animation: filter 1s ease-in-out;
}

@keyframes filter {
  0% {
    right: -400px;
  }

  100% {
    right: 0px;
  }
}

.cart_text {
  font-size: 20px;
}

.side_cart_cross {
  font-weight: 600;
  font-size: 15px;
  border: none;
  background-color: transparent;
  color: red;
}

.side_remove {
  position: absolute;
  bottom: 13px;
  right: 3px;
  font-weight: 700;
  border: none;
  background-color: transparent;
}

.nav_search_icon {
  display: none;
}

.nav_search_btn {
  position: absolute;
  height: 65%;
  top: 12px;
  right: 12px;
  padding: 0px 20px !important;
  font-size: 29px;
  border: none;
  outline: none;
  background-color: red;
  color: white;
}

.nav_cotact input {
  width: 100% !important;
  padding: 10px;
  border: none;
  outline: none;
  background-color: white !important;
  /* border: 1px solid #1b2950; */
  color: #1b2950;
}

.nav_cotact input::placeholder {
  color: #1b2950;
}

.nav_cotact input:focus {
  background-color: transparent;
  color: #1b2950;
  border: none;
  outline: none;
}

.nav1_row {
  display: flex;
  justify-content: space-between;
  padding-right: 18px;
}

.nav_search {
  display: none;
}

.nav_searchbar {
  position: absolute;
  top: 74px;
  right: 0px;
  width: 100%;
  z-index: 1000;
}

.nav_searchbar input {
  width: 100%;
  padding: 8px 7px;
}

.nav_searchbar input:focus {
  color: #1b2950;
  border: none;
  outline: none;

}

.nav_searchbar input::placeholder {
  color: #1b2950;
}

.show_nav {
  max-height: 88vh;
  overflow: auto;
}

.nav_social a {
  width: fit-content;
  height: fit-content;
  padding: 2px 7px;
  border-radius: 100%;
}

.nav_search_margin {
  margin-top: 220px;
}

@media screen and (min-width:769px) and (max-width:992px) {
  .nav_searchbar {
    top: 82px;
  }

}

@media screen and (max-width:1027px) {
  .nav_search_btn {
    top: 11px;
  }
}

@media screen and (max-width: 1230px) {
  .menu_hide {
    display: none;
  }

}

@media screen and (max-width:992px) {
  .nav_search_margin {
    margin-top: 150px !important;
  }

  .navbar {
    padding: 0px !important;
  }

  .nav_cotact {
    display: none;
  }

  .nav_search {
    display: block;
  }

  .nav_search_btn {
    display: none !important;
  }

  .heart {
    display: none;
  }

  .custom-toggler {
    opacity: 1;
  }

  .nav-item2:hover {
    background-color: transparent;
  }

  .menu3 {
    background-color: #F7EEDD !important;
  }

  .nav-item2 {
    background-color: #F7EEDD !important;
  }

  .nav-item2:hover .nav-link {
    color: #1b2950 !important;
  }

  .navbar-custom {
    border: none;
  }

  .nav_cotact,
  .nav_cotact input,
  .nav_cotact .nav_search_btn {
    display: none !important;
  }

  .login_div {
    top: 74px !important;
  }

  .login_div {
    top: 75px;
  }

  .dropdown-menu {
    background-color: #F7EEDD;
    border: none;
  }

  .nav-link {
    border-bottom: 1px solid lightgray;
  }

}

@media screen and (max-width:768px) {
  .nav1_row {
    padding-right: 2px;
  }

  .login_div::before {
    right: 51px !important;
  }

  .nav_cart_div {
    height: 65vh;
  }
}

@media screen and (max-width:820px) {
  .nav_social {
    display: none !important;
  }
}

@media screen and (max-width:560px) {

  .nav_cotact_icon,
  .nav_cotact_icon p {
    font-size: 15px !important;
  }

  .nav_cotact_icon span {
    margin-top: -10px;
  }
}

@media screen and (max-width:462px) {

  .nav_cotact_icon,
  .nav_cotact_icon p {
    font-size: 12px !important;
  }
}

@media screen and (max-width:387px) {
  .nav_contact2 {
    display: none !important;
  }

  .logo_navbar {
    width: 150px;
  }

  .nav_contact3 {
    display: block !important;
  }
}

@media screen and (max-width:320px) {

  .side_cart,
  .side_open {
    width: 250px !important;
  }

  .cart_text,
  .side_remove,
  .side_cart_cross {
    font-size: 14px !important;
  }
}