.all_title {
    text-transform: uppercase;
    text-align: center;
    color: white;
    font-weight: 700;
    font-size: 30px;
}

.all_collection_main:hover {
    cursor: pointer;
}
.all_collection_main:hover .all_img{
    transform: scale(1.5);
    transition: all linear 5s;
}
.h_box:hover{
    cursor: pointer;
}