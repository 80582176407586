.footer_menus p{
    color: #fff;
}
.footer_head_detail {
    color: #fff;
    font-size: 15px;
    line-height: 25px;
    padding: 10px 0px;
    text-transform: capitalize;
    border-bottom: 1px dotted #fff;
}

.footer_address {
    color: #fff;

}

.footer_addres_icon {
    font-size: 15px;
    color: #fff;
}

.footer_explore_detail {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #fff;
    justify-content: space-between;
}
.footer_menus p:hover{
    cursor: pointer;
    border-width: 50%;
    border-bottom: 1px solid rgba(60, 114, 252, 1);
}
.newsletter_box{
    background-color: #000;
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 35px;
    /* align-items: center; */
}
.newsletter_box p{
    font-size: 12px;
    color: #fff;
}
.newsletter_input{
    border: none;
    outline: none;
    height: 70px;
    width: 100%;
}
.newsletter_input:focus{
    border: none;
    outline: none;
    padding: 0px 10px;
}
.newsletter_input::placeholder{
    padding: 0px 10px;
}
.newsletter_btn{
    position: absolute;
    top: 10px;
    right: 10px;
    outline: none;
    border: none;
    padding: 10px 15px;
    color: #ffff;
    font-weight: 500;
    background-color: #02025E;
}
.footer_icon{
    display: flex;
    align-items: center;
    gap: 20px;
}
.footer_icon div{
    font-size: 22px;
    color: #fff;
}
.footer_display{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media screen and (max-width:768px) {
    .newsletter_btn{
        font-size: 10px;
        top: 15px;
    }
    .newsletter_box{
        padding: 0px 15px;
    }
    .footer_display{
        justify-content: center !important  ;
    }
    .footer_display p{
        text-align: center;

    }
}
