
.h_box_main {
    position: relative;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    gap: 30px !important;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}
.h_box:hover .h_box_img_main img {
    cursor: pointer;
    transform: scale(1.09);
    transition: all linear 1s;
}

.h_box_main::-webkit-scrollbar {
    display: none;
}

.hero_main {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.h_box {
    height: fit-content !important;
    width: 250px;
    margin-bottom: 10px;
    flex-shrink: 0;
    box-shadow: 1px 2px 3px 1px rgb(0, 0, 0, 0.5);
}


.h_box_img_main {
    width: 100% !important;
    height: 190px !important;
    overflow: hidden;
}

.h_box_img_main img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
}

.h_box_p {
    font-weight: 700;
}


@media screen and (max-width:768px) {
    .h_box_main {
        width: 100%;
        gap: 10px !important;
    }

    .main_container {
        padding: 30px 10px;
    }
}