.slider-container {
  display: flex;
  overflow: hidden;
  height: fit-content;
}

.slide-text {
  color: rgb(2, 2, 94);
  font-weight: 600;
  font-size: 30px;
  white-space: nowrap;
  animation: moveRightToLeft 60s linear infinite;
  padding-right: 10px; 
}

@keyframes moveRightToLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media screen and (max-width:768px) {
  .slide-text {
    animation: moveRightToLeft 30s linear infinite;
  }
  
  @keyframes moveRightToLeft {
    0% {
      transform: translateX(50%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
    
}