option {
  text-transform: capitalize;
}
.completed-row select {
  color: white;
}

.order_Select {
  border: none;
  outline: none;
  background-color: #2b2c6c;
  color: white;
  padding: 2px;
  border-radius: 5px;
}
.completed-row {
  background-color: rgb(255, 0, 0,0.7);
  color: white;
}

.completed-row:hover {
  background-color: rgb(255, 0, 0);
  /* background-color: rgb(2, 2, 94, 0.8); */
  color: white;
}

.panel_btn {
  font-size: 15px;
  color: #2b2c6c;
  font-weight: 600;
}

.panel_btn:hover {
  cursor: pointer;
}