.card_box {
  width: 250px;
  height: fit-content;
  padding: 5px 0px;
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
}

.card_box:hover {
  cursor: pointer;
  transition: all ease 0.1s !important;
}

.card_img_box {
  width: 100% !important;
  height: 200px !important;
  border-radius: 7px;
  position: relative;
  overflow: hidden;
}

.card_img_box img {
  border-radius: 7px;
  width: 100%;
  height: 100%;
  
}

.card_img_box:hover {
  transform: scale(1.09);
  transition: all linear 2s;
}

.discount {
  position: absolute;
  width: fit-content;
  padding: 2px 15px;
  background-color: rgb(255, 0, 0);
  top: 10px;
  border-radius: 40px;
  color: white;
}

.card_title {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 10px 0px 0px 0px;
  width: 100%;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card_price {
  color: #fd5d39;
}

.card_Fprice {
  font-weight: 600;
  margin-right: 4px;
}

.card_btns {
  display: none;
  width: 100%;
  height: 110px;
}

.card_box:hover .card_btns {
  display: block;
}

.order_btn {
  position: absolute;
  right: -200px;
  bottom: 55px;
  border: none;
  outline: none;
  background-color: rgb(2, 2, 94, 0.9);
  color: white;
  width: 80%;
  font-weight: 500;
  padding: 10px 0px !important;
}

.view {
  font-weight: 600;
  color: rgb(2, 2, 94);
  border-bottom: 1px solid rgb(2, 2, 94);
}

.view:hover {
  cursor: pointer;
}


.card_whatsAp {
  position: absolute;
  left: -200px;
  bottom: 6px;
  font-size: 15px;
  border: none;
  outline: none;
  background-color: rgb(33, 211, 102, 0.9);
  color: white;
  width: 80%;
  font-weight: 500;
  padding: 10px 20px;
}

.order_btn:hover {
  font-weight: 600;
  color: white;
  background-color: rgb(2, 2, 94);
  transition: all linear 0.1s;
}

.card_whatsAp:hover {
  font-weight: 600;
  color: white;
  background-color: rgb(33, 211, 102);
  transition: all linear 0.1s;
}

.card_box:hover .order_btn {
  right: 25px !important;
  transition: all ease-in-out 0.4s;
}

.card_box:hover .card_whatsAp {
  left: 25px !important;
  transition: all ease-in-out 0.8s;
}

.card_img_box .overlay {
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  transition: 0.3s ease;
}

.card_img_box:hover .overlay {
  left: 0;
}

.bed_left,
.bed_right {
  position: absolute;
  top: 80px;
  background-color: #F7EEDD;
  /* background-color: rgb(27, 41, 80, 0.8); */
  color: rgb(27, 41, 80);
  border-radius: 100%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
}

.bed_left:hover,
.bed_right:hover {
  background-color: rgb(27, 41, 80);
  color: white;
}

.bed_left {
  left: 0px;
}

.bed_right {
  right: 0px;
}
.hidden {
  display: none;
}

@media screen and (max-width:768px) {

  .bed_left,
  .bed_right {
    display: none !important;
  }
}