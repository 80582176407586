.about_text{
    color: #fff;
    font-size: 14px;
    text-transform: capitalize;
}
.hero_facebook{
    padding: 10px 40px;
    font-weight: 600;
    color: white;
    border: none;
    outline: none;
    background-color: rgb(24, 119, 242) ;
}
.hero_facebook:hover{
    color: white;
    background-color: rgb(24, 119, 242) ;
    transform: scale(1.02);
    transition: all linear 0.3s;
}

@media screen and (max-width:990px){
    .about_img{
        display: none;
    }
}