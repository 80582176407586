.hero_main {
    width: 100%;
}

.slide {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    object-fit: contain;
}

.slide_detail {
    position: absolute;
    width: 250px;
    padding: 10px !important;
    bottom: 0px;
    left: 0px;
    word-wrap: break-word;
    display: flex;
    border-radius: 5px;
}

.slide_detail .slide_title {
    font-size: 70px;
    margin-top: 10px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    -webkit-text-stroke: 2px white;
    -webkit-text-fill-color: transparent;
}

.slide_detail .slide_offer {
    position: absolute;
    width: fit-content;
    height: fit-content;
    font-size: 20px;
    padding: 5px 10px;
    top: 0px;
    left: -40px;
    color: white;
    font-weight: 600;
    border-radius: 5px;
    transform: rotate(-33deg);
}

.slide,
.hero_slide {
    display: block;
    width: 100%;
    height: 600px;
    object-fit: cover;
    overflow: hidden;
}
.hero_slide img{
    width: 100%;
    height: 100%;
}
/* .hero_box {
    width: 100%;
    height: 600px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    animation: slide 55s infinite 0s;
}

@keyframes slide {
    0% {
        background-image: url("./6.jpg");
    }

    25% {
        background-image: url("./4.jpg");
    }

    50% {
        background-image: url("./foot.jpg");
    }

    75% {
        background-image: url("./AdobeStock_246454872_Preview.jpeg");
    }

    100% {
        background-image: url("./3.jpg");
    }
}
 */

.hero_shop {
    position: absolute;
    bottom: 30px;
    left: 30px;
    background-color: #1b2950;
    border: none;
    outline: 1px solid #F7EEDD;
    color: #F7EEDD;
    padding: 12px 30px;
    font-weight: 600;
}

.hero_shop:hover {
    color: #F7EEDD;
    background-color: #1b2950;
    transform: scale(1.02);
    transition: all linear 0.3s;
}


@media screen and (max-width:768px) {
    .hero_heading {
        font-size: 25px;
    }

    .hero_text {
        font-size: 15px;
    }

    .hero_main {
        width: 100%;
    }

    .swiper-slide img,
    .hero_box,
    .hero_slide {
        height: 320px;
    }

}